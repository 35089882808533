import React from 'react';

import { Layout, Typography, Image } from '../components';

const Lequipe = () => (
  <Layout
    title="Equipe médicale de Chirurgie Orthopédiaque à Paris"
    description="Le Dr Ghazanfari intervient sur plusieurs sites, l'Espace Médical Vauban dans le 7éme, la Clinique du sport, le centre hospitalié de Montargé..."
    index={false}
    follow={false}
    headerStyle="white"
  >
    <div className="bg-white">
      <div className="max-w-screen-md px-4 py-12 mx-auto text-center">
        <Typography className="uppercase tracking-[0.3em]" variant="h1" color="secondary-deep" size="lg" weight="bold">L&apos;équipe</Typography>
      </div>
      <Image className="xl:h-[600px]" imageName="equipe.png" />
      <div className="px-4 py-16 mx-auto space-y-8 max-w-screen-mdx">

        <div className="flex flex-col space-y-4 divide-y divide-[#ececec]">
          <Image className="h-28" fit="scale-down" imageName="logo-centre-hospitalier-montgarde.png" />
          <Typography className="py-4 text-justify">
            J&apos;exerce au sein de l&apos;UMVS (Urgences Mains Val de Seine), unité spécialisée
            dans la chirurgie du membre supérieur du Centre Hospitalier Privé du Montgardé
            à Aubergenville (78). test Cette unité, créée il y a plus de 20 ans, est le
            premier SOS-Main du département des Yvelines et est accrédité par la FESUM
            (Fédération Européenne des Services d&apos;Urgences de la Main). Nos secrétaires
            (Sabah, Cindy, Morgane et Frédérique) assurent les prises de rendez-vous
            et l&apos;organisation de votre opération dans cet établissement. Notre infirmière
            Justine, s&apos;occupe des pansements dans le cadre du SOS-Main et en sortie
            d&apos;hospitalisation. Raymond notre kinésithérapeute, spécialisé dans la rééducation
            du membre supérieur, est également disponible afin d&apos;assurer une récupération
            fonctionnelle rapide et de qualité. Enfin, Emmanuelle notre orthésiste,
            est à votre disposition pour la réalisation d&apos;orthèses thermoformées sur-mesure.
          </Typography>
        </div>

      </div>
    </div>

  </Layout>
);

export default Lequipe;
